import React from 'react'
import Img from 'gatsby-image'

import styles from './heroLayout.module.css'

export default ({ data }) => (
  <div className={styles.hero}>
    <Img
      className={styles.heroImage}
      alt={data.title}
      fluid={data.backgroundImage.fluid}
    />
    <div className={styles.heroDetails}>      
      <p className={styles.heroTitle}>{data.headline}</p>      
    </div>
  </div>
)
