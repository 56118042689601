import React from 'react'

import styles from './CAMPS.module.css'

export default () => 
(
  <div>
    <p>
        We organise camps and outings for the children from our Bible clubs every year. This is a highlight for the children, as many of
        them come from poor communities where they do not have many opportunities.
    </p>    
    <p>
        The camps and outings provide precious opportunities for building deeper relationships and disciple making. Weekend programs
        are packed with fun activities, Bible teaching, small group discussions, watching a Biblical movie, praise and worship time, creative
        activities and playing games. The devoted kitchen crew loves to spoil the children with nutritious meals and treats and the children
        always complain that the weekend has been too short!
    </p>
    <p>
        Older children are given the opportunity to become team leaders and are mentored to develop leadership skills. We also organise
        camps specifically for teenagers who had been in Bible clubs since primary school.
    </p>
    <u><strong>Some children who attended the camps said:</strong></u>
    <ul>
        <li>
            “I am going to keep on praying and reading the Bible, because on this camp I learned more about Jesus.”
        </li>
        <li>
            “I will remember that God always loves me and I belong to Him.”
        </li>
        <li>
            “The most important thing is that Jesus should be first in your life.”
        </li>
        <li>
            “I learned to be respectful, obedient and work well together in a team.”
        </li>
        <li>
            “I learned that God can change your heart and that I could become God’s child.”
        </li>
        <li>
            “Thank you for teaching us how to use a Bible properly.”
        </li>
        <li>
            “We as teenagers have many choices in life. Sometime we make the wrong choices and do not think what<br/>
            Jesus would have done. But God will always receive you with open arms when you come back to Him.”
        </li>
        <li>
            “I enjoyed the treasure hunt, games, singing and food most of all.”
        </li>
    </ul>
  </div>
)
